<template>
	<div>
		<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>
					<v-icon class='mb-1'>mdi-account-key</v-icon>
					GRUPO DE PERMISOS
				</v-toolbar-title>
				<v-divider
					class="mx-2"
					inset
					vertical
				></v-divider>
				<v-col cols="3">
					<v-text-field
						
						v-model="searchGroupName"
						append-icon="mdi-magnify"
						label="Buscar"
						single-line
						class='mt-5 mx-2'
						@keyup.enter="cargarLista()"
					></v-text-field>
				</v-col>
				<v-col cols="3">
					<v-btn 
						title="Crear un nuevo grupo"
						small
						class="mx-2"
						color = 'primary'
						@click="crearGrupoPermisos"
					> 
						<v-icon>mdi-plus-circle-outline</v-icon> Nuevo grupo
					</v-btn>
				</v-col>

				<v-col cols="3">
					<v-btn 
						title="Ir a la lista de permisos"
						small
						dark
						target="_blank"
						to="/security_permission"
						class="mx-2"
						color = 'blue'						
					> 
						<v-icon>mdi-view-list-outline</v-icon>Permisos
					</v-btn>
				</v-col>
			</v-toolbar>
			<v-data-table
				:headers="headers"
				:items="lista"
				:items-per-page=itemsPerPage				
				:loading=loadingTable
				hide-default-footer
				class="elevation-1"
			>
				<template v-slot:item.opcion="{item}">
					<v-btn 
						icon 
						color='green' 						
						@click="abrirFormSecurityGroup(item.group_id)"
					>
						<v-icon >mdi-pencil</v-icon>
					</v-btn>					
				</template>
				
				<template v-slot:item.description="{item}">
					<v-btn 
						icon 
						color='red' 
						target="_blank" 
						@click="editarGrupoPermisos(item.group_id, item.description)"
					>
						<v-icon color='#119800' >mdi-square-edit-outline</v-icon>						
					</v-btn>					
					{{item.description}}
				</template>

				<template v-slot:footer>        
                <v-pagination
                class="mt-10"
                v-model="currentPage"
                :length="pageCount"
                @input="handlePageChange"
                total-visible="10"
                ></v-pagination>
				</template>
			</v-data-table>
		</v-card>
		<v-dialog
			v-model="dialog"
			fullscreen
			transition="dialog-bottom-transition"
			persistent
			no-click-animation
		>
			<v-card >
				<v-toolbar
					dark
					small
					color="primary"
				>
					<v-btn
						icon
						dark
						@click="dialog = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Grupo de seguridad {{securityGroupId}}  </v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
						dark
						text
						@click="dialog = false"
						>
						Salir
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card class="pl-6 pr-6 pt-6">
				
					
					<v-tabs vertical>
						<v-tab>
							<v-icon left>mdi-account-key</v-icon>
							Permisos
						</v-tab>
						<v-tab>
							<v-icon left>mdi-account</v-icon>
							Usuarios
						</v-tab>
						<v-tab-item>
							<v-toolbar flat>
								<v-text-field
									v-model="searchPermissionGroup"
									append-icon="mdi-magnify"
									label="Buscar"
									single-line
									class='mt-5'
								></v-text-field>
								<v-spacer></v-spacer>
								<v-btn
									dense
									small
									color="info"
									@click="formPermiso=true"
								>
									<v-icon>mdi-plus</v-icon>
									Agregar
									
								</v-btn>
							</v-toolbar>
							<v-data-table
								:headers="headPermisos"
								:items="permisos"
								:items-per-page=itemsPerPage
								:search=searchPermissionGroup
							>
								<template v-slot:item.opcion="{item}">
									<v-btn 
										icon 
										color='red' 
										@click="eliminarPermiso(item.permission_id)"
									>
										<v-icon  >mdi-delete</v-icon>
									</v-btn>
								</template>
							</v-data-table>
						</v-tab-item>
						<v-tab-item>
							<v-card flat>
								<v-card-text>
									<v-toolbar flat>
										<v-text-field
											v-model="searchUsers"
											append-icon="mdi-magnify"
											label="Buscar"
											single-line
										></v-text-field>
										<v-spacer></v-spacer>
										<v-btn
											dense
											small
											color="info"
											@click="formUsuario=true"
										>
										<v-icon>mdi-plus</v-icon>
											Agregar
											
										</v-btn>
									</v-toolbar>
									<v-data-table
										:headers="headUsers"
										:options.sync="options"
										:items="usuarios"
										:items-per-page=itemsPerPage
										hide-default-footer
										:search=searchUsers
									>
										<template v-slot:item.opcion="{item}" >
											<v-btn 
												icon 
												color='red' 
												
												@click="eliminarUsuario(item.user_login_id,item.tenant_id)"
											>
												<v-icon  >mdi-delete</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-card-text>
							</v-card>
						</v-tab-item>
					</v-tabs>
				</v-card>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="formUsuario"
			persistent
			max-width="400px"
		>
			
							
			<v-card>
				<v-card-title>
				<span class="headline">Agregar usuario</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="formUsuario">
							<v-row>
								<v-col class="pt-0 pb-0" cols="12">
									<v-autocomplete
										v-model="users"
										small
										item-text="user_login_id"
										item-value="user_login_id"
										:items="usuariosActivos"
										label="Usuarios"
										multiple
										:rules=requiredRule
									></v-autocomplete>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="pt-0 pb-0" cols="12">
									<v-select
										v-model="tenantIdPermission"
										small
										item-text="name"
										item-value="tenantId"
										:items="companies"
										label="Tenant"
										:rules=requiredRule
									></v-select>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-col class='text-center'>
						<v-btn
							small
							class="mx-4"							
							color="info"
							@click="agregarUsuario()"
						>
							<v-icon>mdi-content-save</v-icon> Guardar
						</v-btn>
						<v-btn
							small
							class="mx-4"							
							@click="formUsuario = false"
						>
							<v-icon>mdi-cancel</v-icon> Cancelar
						</v-btn>
						
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="formPermiso"
			persistent
			max-width="500px"
		>	
			<v-card>
				<v-card-title>
					<span class="headline">Agregar permiso</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="formPermiso">
							<v-row>
								<v-col class="pt-0 pb-0" cols="12" >
									<v-autocomplete
										clearable
										v-model="permissionsId"
										small
										item-text="description"
										item-value="permission_id"
										:items="securityPermissions"
										label="Seleccione los permisos"
										return-object
										multiple
									></v-autocomplete>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions >
					<v-col class='text-center'>
						<v-btn
							small
							class="mx-4"
							color="primary"
							@click="asignarPermisos()"
						>
							<v-icon>mdi-content-save</v-icon> Guardar
						</v-btn>
						<v-btn
							small
							class="mx-4"
							@click="formPermiso = !formPermiso"
						>
							<v-icon>mdi-cancel</v-icon> Cancelar
						</v-btn>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>	
		<v-dialog
			v-model="formSecurityGroup"
			persistent
			max-width="500px"
		>	
			<v-card>
				<v-card-title>
					<span class="headline">Nuevo Grupo de permisos</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="formSecurityGroup">
							<v-row>
								<v-col class="pt-0 pb-0" cols="12" >
									<v-text-field 
									v-model="sgGroupId"
									label="Codigo"
									:disabled="sgEdit"
									>
									</v-text-field>

								</v-col>
							</v-row>
							<v-row>
								<v-col class="pt-0 pb-0" cols="12">
									<v-text-field
										v-model="sgGroupName"
										label="Nombre del grupo"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions >
					<v-col class='text-center'>
						<v-btn
							color="primary"
							@click="guardarGrupo"
						>
							<v-icon>mdi-content-save</v-icon> Guardar
						</v-btn>
						<v-btn
							@click="formSecurityGroup = !formSecurityGroup"
						>
							<v-icon>mdi-cancel</v-icon> Cancelar
						</v-btn>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import Vue from 'vue'

export default {
	name: "SecurityGroupsComponent",
	data: ()=> ({
    	headers: [
			{ text: 'Opciones', value: 'opcion' },
			{ text: 'Codigo', value: 'group_id' },
			{ text: 'Nombre', value: 'description' },
        ],
		headUsers: [
			{ text: 'Opciones', value: 'opcion' , align:"center", width:150},
			{ text: 'Tenant', value: 'tenant_id' , align:"start"},
			{ text: 'User Id', value:'user_login_id' , align:"start"},
			{ text: 'Activo', value: 'enabled' , align:"center"},
		],
		headPermisos: [
			{ text: 'Opciones', value: 'opcion' , align:"center", width:150},
			{ text: 'Permission Id', value:'permission_id' , align:"start"},
			{ text: 'Descripcion', value: 'description' , align:"start"},
		],
		itemsEstado: [ 
			{'estadoId': 'INVOICE_READY','estadoNombre':'Listo'},
			{'estadoId':'INVOICE_PAID','estadoNombre': 'Pagado'}
		],
		requiredRule:[
            v => !!v || 'El campo es requerido'
        ],
		lista :[],
		options: {},
		currentPage:1,
		pageCount :0,
		totalItems:0,
		dialog: false,
		notifications: false,
		sound: true,
		widgets: true,
		permissionsId:null,
		securityGroupId:null,		
		tenantIdPermission:null,
		searchGroupName: null,
		searchPermissionGroup:null,
		searchUsers:null,
		tentantId:null,
		users:null,
		securityPermissions:[],
		permisos: [],
		usuarios: [],
		usuariosActivos: [],
		formUsuario: false,
		permisosDisp : [],
		formPermiso: false,
		itemsPerPage: 20,
		searchGroupId: "",
		searchNombreGrupo: "",
		
		sgGroupId:null,
		sgGroupName:null,
		formSecurityGroup: false,
		sgEdit:false
    }),
    computed: {

    	...mapState('master',['loadingTable','user','tenantId','companies']),
    	
    	...mapGetters('access', ['btnAbrirCaja'])

    },
    methods: {

    	...mapMutations('master',['setUrl','setOverlay','setMenu','setLoadingTable']),
	   	 
	    ...mapActions('master',['requestApi','alertNotification']),

		crearGrupoPermisos(){
    		this.sgEdit = false
    		this.sgGroupId = "";
    		this.sgGroupName = "";
			this.formSecurityGroup = true
		},
		
		editarGrupoPermisos(gid,gname){
			this.sgGroupId = gid
			this.sgGroupName =  gname
			this.sgEdit = true
			this.formSecurityGroup = true
		},
		guardarGrupo(){
			
			if(this.sgEdit == false) {
	            this.setUrl('security-group')
	            this.requestApi({
	                method : 'POST',
	                data : {
				       	"group_id":   this.sgGroupId,
				       	"description": this.sgGroupName
				    }
	            }).then(res=>{
	
	            	console.log(res);			        			    		
	            	this.cargarLista()							    	
				
	            }).catch(()=>{
		              
	            }).then(()=>{
	            	this.formSecurityGroup = false
				})
			} 
			if(this.sgEdit == true ) {
				this.setUrl('security-group/' + this.sgGroupId)
	            this.requestApi({
	                method : 'PATCH',
	                data : {			       	
				       	"description": this.sgGroupName
				    }
	            }).then(res=>{

	            	console.log(res);			        			    		
	            	this.cargarLista()							    	
				
	            }).catch(()=>{
		              
	            }).then(()=>{
	            	this.formSecurityGroup = false
				})
				
				
			}
			
		},
		
		
	     
        cargarLista() {
			
			this.setLoadingTable(true)
            this.setUrl('security-group')
            this.requestApi({
                method : 'GET',
                data : {
					page: this.currentPage,
                    page_size: this.itemsPerPage,	 
					groupName: this.searchGroupName
			    }
            }).then(res=>{

            	console.log(res);			        			    		
            	this.lista = res.data._embedded.security_group;
            	this.pageCount = res.data.page_count;		
				//this.currentPage = res.data.page;
				this.totalItems = res.data.total_items
			
            }).catch(()=>{
	              
            }).then(()=>{
				this.setLoadingTable(false)
			})
        	  
        },
		cargarDatosGrupo(sgid) {
			this.setUrl('security-group/'+sgid)
            this.requestApi({
                method : 'GET',
                data : {}
            }).then(res=>{
				console.log(res.data)
            	this.securityGroupId = sgid
				this.permisos = res.data.permissions;
				this.usuarios = res.data.users;	
				this.securityPermissions = res.data.securityPermissions		
				this.usuariosActivos =res.data.loginUsers					

            }).then(()=>{
	              
            })
		},
        abrirFormSecurityGroup(sgid) {
			this.cargarDatosGrupo(sgid)
			this.dialog = true            
        },

		asignarPermisos(){

			this.setUrl('user-security-group')
            this.requestApi({
                method : 'POST',
                data : {
					securityGroupPermissionId: this.securityGroupId,
					permissionsId: this.permissionsId.map(obj=>{ return obj.permission_id }),
					typeStore:'asignarPermisos'
				}
            }).then(res=>{
            				        			    		
				this.permissionsId.forEach((obj)=>{
					this.permisos.push({
						description: obj.description,
						group_id:this.securityGroupId,
						permission_id :obj.permission_id
					})
				})
				this.alertNotification({param:{html: res.data.res.msg}})
				

            })

		},

		eliminarPermiso(permissionId){

			Vue.swal({
            html: "Está seguro de eliminar el permiso?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cerrar',
            ...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {

					this.setUrl('user-security-group')
					this.requestApi({
						method : 'DELETE',
						data : {
							securityGroupPermissionId: this.securityGroupId,
							permissionId: permissionId,
							typeDelete:'eliminarPermiso'
						}
					}).then(res=>{
																	
						this.alertNotification({param:{html: res.data.detail}})
						let obj = this.permisos.find(e => e.permission_id == permissionId)
						let index = this.permisos.indexOf(obj)
						this.permisos.splice(index,1)

					})
					
				}
				
			});

		},

        agregarUsuario() {

			if(!this.$refs.formUsuario.validate())
				return false

        	this.setUrl('user-security-group')
            this.requestApi({
                method : 'POST',
                data : {
					tenantPermission: this.tenantIdPermission,
					users: this.users,
					securityGroupId: this.securityGroupId,
					typeStore:'asignarUsuario'
				}
            }).then(res=>{
            				        			    		
				this.users.forEach((obj)=>{
					console.log(obj)
					this.usuarios.push({
						user_login_id: obj,
						tenant_id:this.tenantIdPermission,
						group_id: this.securityGroupId,
						enabled:'Y'
					})
				})
				this.alertNotification({param:{html: res.data.res.msg}})

            })

        },

		eliminarUsuario(userLoginId,tentantId){

			Vue.swal({
				html: "Está seguro de quitar al usuairo del grupo de permiso?",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Aceptar',
				cancelButtonText: 'Cerrar',
				...this.paramAlertQuestion

            }).then( result => {
                if (result.isConfirmed) {

					this.setUrl('user-security-group')
					this.requestApi({
						method : 'DELETE',
						data : {
							securityGroupPermissionId: this.securityGroupId,
							tentantId:tentantId,
							userLoginId: userLoginId,
							typeDelete:'eliminarUsuario'
						}
					}).then(res=>{
																	
						this.alertNotification({param:{html: res.data.detail}})
						let obj = this.usuarios.find(e => e.user_login_id == userLoginId && e.tenant_id == tentantId)
						let index = this.usuarios.indexOf(obj)
						this.usuarios.splice(index,1)

					})
					
				}
				
			});

		},
		handlePageChange(value) {
            this.cargarLista()
        },
        
    },
	mounted(){
		this.cargarLista()
	}
    
  }

</script>